/** @jsx jsx */
import { jsx } from "theme-ui"

import StickyResponsiveSidebar from "./sticky-responsive-sidebar"
import { DocsSidebarNav } from "./docs-sidebar-nav"

export default function NewStickyResponsiveSidebar(props) {
  return (
    <StickyResponsiveSidebar
      activeUrl={props.location.pathname}
      CustomSidebar={DocsSidebarNav}
    />
  )
}
