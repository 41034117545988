import { HowToGuideIcon } from "../components/shared/icons/HowToGuideIcon.js"
import { DocScrollIcon } from "../components/shared/icons/DocsScrollIcon.js"
import { ReferenceGuideIcon } from "../components/shared/icons/ReferenceGuideIcon.js"
import { TutorialIcon } from "../components/shared/icons/TutorialIcon.js"
import { ConceptualGuideIcon } from "../components/shared/icons/ConceptualGuideIcon.js"

const getDocsIconComponent = iconLabel => {
  if (iconLabel === "How-to") {
    return HowToGuideIcon
  } else if (iconLabel === "Documentation") {
    return DocScrollIcon
  } else if (iconLabel === "Reference") {
    return ReferenceGuideIcon
  } else if (iconLabel === "Tutorial") {
    return TutorialIcon
  } else if (iconLabel === "Conceptual") {
    return ConceptualGuideIcon
  }

  return null
}

export default getDocsIconComponent
